<template>
  <div class="container">
    <div class="questions" ref="questions">
      <div class="question-list">
        <div v-for="(item, index) in questions" :key="index" class="question-item" :class="selectId === index ? 'active' : ''">
            <span class="question-name">{{ item.questionName }}</span><span class="question-score">：{{ setShow(item.questionScore) }}分</span>
        </div>
      </div>
    </div>
    <div class="top"></div>
    <div class="center"></div>
    <div class="bottom"></div>
    <el-button class="up" icon="el-icon-arrow-up" @click="scrollUp"></el-button>
    <el-button class="down" icon="el-icon-arrow-down" @click="scrollDown"></el-button>
  </div>
</template>

<script>
export default {
  name: 'ScrollSelect',
  props: ['questions'],
  data () {
    return {
      selectId: 0
    }
  },
  watch: {
    selectId(val) {
      this.$emit('changeQuestion', this.questions[val])
    }
  },
  methods: {
    scrollUp() {
      if (this.questions.length >0 && this.selectId < this.questions.length -1) {
        this.selectId += 1
        this.$refs.questions.scrollTop += 44
      }
    },
    scrollDown() {
      if (this.questions.length >0 && this.selectId > 0) {
        this.selectId -= 1
        this.$refs.questions.scrollTop -= 44
      }
    },
    // 设置小数点
    setShow(score){
      return (typeof score === "number" ? score.toFixed(1) : score)
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  //padding-bottom: 97px;
  //overflow: auto;
  height: 220px;
  position: relative;
  .questions{
    padding-top: 88px;
    padding-bottom: 88px;
    height: 220px;
    width: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display: none;
    }
    .question-list{
      width: 240px;
      .question-item{
        height: 44px;
        line-height: 44px;
        width: 240px;
        color: #999999;
        .question-name{
          max-width: 163px;
          display: inline-block;
          float:left;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .question-score{
          display: inline-block;
          float:left;
        }
        &.active{
          color: #333333;
          font-weight: bold;
        }
      }
    }
  }
  .center{
    position: absolute;
    left: 0;
    top: 88px;
    height: 44px;
    width: 240px;
    background-color: rgba(255, 255, 255, 0);
    //background-color: red;
  }
  .top, .bottom{
    position: absolute;
    left: 0;
    height: 88px;
    width: 240px;
  }
  .top{
    top: 0px;
    background-image: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255, 0.2));
    border-bottom: 1px solid #E5E5E5;
  }
  .bottom{
    bottom: 0px;
    background-image: linear-gradient(180deg, rgba(255,255,255,0.2), rgba(255,255,255,1));
    border-top: 1px solid #E5E5E5;
  }
  .up, .down{
    position: absolute;
    right: 0;
    display: flex;
    border: none;
    font-size: 20px;
    padding-left: 0;
    padding-right: 0;
    &:hover{
      background-color: #fff;
    }
  }
  .up{
    top: 60px;
  }
  .down{
    bottom: 60px;
  }
}
</style>
