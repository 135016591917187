<template>
  <div ref="gauge" class="gauge"></div>
</template>

<script>
export default {
  name: 'Gauge',
  methods: {
    initCharts ({dataParam}) {
      const echarts = require('echarts')
      let { myChart } = this
      if (!myChart) {
        myChart = echarts.init(this.$refs.gauge)
        this.myChart = myChart
      } else {
        myChart.clear()
      }
      const option = {}
      Object.assign(option, dataParam)
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
  .gauge {
    width: 180px;
    height: 180px;
    margin: 0 auto;
  }
</style>
