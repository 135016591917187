<template>
  <article v-loading="loading">
    <RouterNav :name="name"></RouterNav>
    <div class="class-exam-detail">
      <div class="content-info">
        <div class="info-test-name">
          <span>{{ classExamInfo.testName }}</span>
        </div>
        <div class="info-desc date-start">考试时间：{{ classExamInfo.dateStart | trimT }}</div>
        <div class="info-desc paper-group">试题：{{ studentReportInfo.paperGroupName }}</div>
      </div>
      <div class="content-detail">
        <div class="item-title">学生成绩</div>
        <div class="student-record">
          <div class="all-accuracy record-info">
            <Gauge ref="allAccuracy"></Gauge>
            <div class="info-content">
              <div class="info-text">
                <span class="info-text-title">总成绩：</span>
                <span class="score-color" :class="persional.accuracyRate >= 85 ? 'green' : (85 > persional.accuracyRate && persional.accuracyRate >= 60 ? 'yellow' : '')">{{ (parseInt(persional.score *100)/100).toFixed(1) }}/{{ persional.totalScore }}分</span>
              </div>
              <div class="info-text">
                <span class="info-text-title">班级排名：</span>
                <span class="blue">第{{ persional.index }}名</span>
              </div>
            </div>
          </div>
          <div class="record-info">
            <Gauge ref="eachQuestion" class="gauge"></Gauge>
            <div class="info-content" >
              <ScrollSelect :questions="eachQuestionRecordList" @changeQuestion="changeQuestion"/>
            </div>
          </div>
        </div>
        <div class="item-title">同比班级平均得分</div>
        <div class="average-score">
          <div ref="echarts" class="average-score-echarts"></div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import RouterNav from '@/components/RouterNav.vue'
import { personalReport} from '@/api/official'
import Gauge from '../report/components/Gauge'
import ScrollSelect from '@/components/common/scrollSelect'
import echarts from "echarts";
export default {
  name: 'StudentReport',
  components: {
    RouterNav,
    Gauge,
    ScrollSelect
  },
  data() {
    return {
      loading: false,
      name: '',
      classExamInfo: {},
      studentReportInfo: {},
      percentage: 10,
      colors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#5cb87a', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#6f7ad3', percentage: 100}
      ],
      persional: {},
      questions: [],
      avgAccuracyList: [],
      eachQuestionRecordList: []
    }
  },
  created() {
    this.classExamInfo = JSON.parse(sessionStorage.getItem('classExamDetail'))
    this.studentReportInfo = JSON.parse(sessionStorage.getItem('studentReport'))
    this.name = this.studentReportInfo.examineeName + '的考试报告'
    this.personalReport()
  },
  methods: {
    // 获取学生个人报告
    async personalReport() {
      this.loading = true
      const data = {
        examineeId: this.studentReportInfo.examineeId,
        batchNum: this.classExamInfo.batchNum,
        clazzId: this.classExamInfo.clazzId
      }
      const res = await personalReport(data)
      this.loading = false
      if (res.state === '11') {
        this.persional = res.data.persional
        this.questions = res.data.questions
        this.avgAccuracyList = res.data.avgAccuracyList

        this.eachQuestionRecordList = this.questions.filter(question => {
          return question.questionName !== '总成绩'
        })
        this.initGaugeCharts(this.$refs.allAccuracy, { questionName: '得分率', questionAccuracy: this.persional.accuracyRate })
        this.initGaugeCharts(this.$refs.eachQuestion, this.eachQuestionRecordList[0])
        this.setChartsData()
      }
    },
    // 设置图表数据
    setChartsData() {
      let data = []
      let series = []
      if (Array.isArray(this.questions) && this.questions.length > 0) {
        const obj = {
          name: this.studentReportInfo.examineeName,
          type: 'bar',
          data: [],
          barGap: 0,
          tooltip: {
            trigger: 'item',
            confine: true,
            triggerOn: 'click',
            formatter (params) {
              return `${params.seriesName}<br />${params.marker}${params.name} ${params.value}%`
            }
          },
        }
        this.questions.forEach(score => {
          data.push(score.questionName)
          obj.data.push({
            name: score.questionName,
            value: score.questionAccuracy,
            monicker: this.studentReportInfo.examineeName
          })
        })
        series.push(obj)
      }
      if (Array.isArray(this.avgAccuracyList) && this.avgAccuracyList.length > 0) {
        const obj = {
          name: this.classExamInfo.clazzName,
          type: 'bar',
          data: [],
          barGap: 0,
          tooltip: {
            trigger: 'item',
            confine: true,
            triggerOn: 'click',
            formatter (params) {
              return `${params.seriesName}<br />${params.marker}${params.name} ${params.value}%`
            }
          },
        }
        this.avgAccuracyList.forEach(score => {
          obj.data.push({
            name: score.questionName,
            value: score.questionAccuracy,
            monicker: this.studentReportInfo.examineeName
          })
        })
        series.push(obj)
      }
      this.initCharts(series, data)
    },
    // 初始化图表
    initCharts (series, data) {
      const echarts = require('echarts')
      this.myChart = echarts.init(this.$refs.echarts)
      const { rangeArr } = this
      const that = this
      this.myChart.setOption({
        color: ['#309AF2','#E5E5E5'],
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        legend: {
          data: [this.studentReportInfo.examineeName, this.classExamInfo.clazzName],
          right: 28
        },
        grid: {
          left: '1%',
          right: 0,
          bottom: 10,
          containLabel: true
        },
        xAxis: {
          // name: '得分率',
          axisLabel: {
            show: true,
            textStyle: {
              color: '#666'
            },
            emphasis: {
              color: '#fff'
            }
          },
          data
        },
        yAxis: {
          name: '得分率（%）',
          max: 100,
          min: 0
        },
        series
      }, true)
    },
    // 设置仪表盘
    initGaugeCharts (target, data) {
      const option = {
        series: [{
          type: 'gauge',
          splitNumber: 1,
          progress: {
            show: true,
            width: 14,
            itemStyle: {
              color: '#3875FD'
            },
            roundCap: true
          },
          axisLine: {
            lineStyle: {
              width: 14,
              color: [
                [1, '#F6F6F6']
              ]
            },
            roundCap: true
          },
          axisTick: {
            show: false
          },
          pointer: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisLabel: {
            show: true,
            distance: -35,
            fontSize: 10,
            color: '#1B6BD4'
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            fontSize: 17,
            offsetCenter: [0, 0],
            formatter: function (value) {
              return value + '%\n{unit|' + data.questionName + '}'
            },
            rich: {
              unit: {
                fontSize: 12,
                color: '#999'
              }
            },
            color: '#333333'
          },
          data: [{
            value: data.questionAccuracy
          }]
        }]
      }
      target.initCharts({
        dataParam: option
      })
    },
    changeQuestion(data) {
      this.initGaugeCharts(this.$refs.eachQuestion, data)
    }
  }
}
</script>

<style lang="scss" scoped>
.class-exam-detail {
  padding-left: 20px;
  .content-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px 28px;
    margin-bottom: 10px;
    .info-test-name {
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .info-desc {
      font-size: 15px;
      line-height: 21px;
      margin-top: 10px;
      &.info-info {
        color: #333333;
      }
      &.date-start, &.paper-group {
        color: #999999;
      }
    }
  }
  .content-detail{
    .item-title{
      background-color: #FFFFFF;
      height: 66px;
      line-height: 24px;
      position: relative;
      padding: 20px 0 20px 28px;
      font-size: 17px;
      color: #333333;
      //border-left: 1px solid #309AF2;
      &:after{
        content: '';
        height: 20px;
        width: 4px;
        background-color: #309AF2;
        position: absolute;
        left: 0;
        top: 22px;
      }
    }
    .student-record{
      background-color: #FFFFFF;
      margin-bottom: 10px;
      padding: 0 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .record-info{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        height: 220px;
        .gauge{
          width: 220px;
        }
        .info-content{
          text-align: left;
          width: 180px;
          flex: 1;
          .info-text{
            color: #333333;
            line-height: 24px;
            font-size: 17px;
            .info-text-title{
              font-weight: bold;
            }
            .score-color{
              color: #FF3C30;
              &.green{
                color: #1DC060;
              }
              &.yellow{
                color: #FFA516;
              }
            }
            .blue{
              color: #309af2;
            }
          }
        }
      }
      .all-accuracy{
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        .info-content{
          width: 180px;
        }
      }
    }
    .average-score{
      width: 1080px;
      height: 300px;
      padding: 0 28px 10px;
      background-color: #fff;
      .average-score-echarts{
        width: 1024px;
        height: 300px;
        //border: 1px solid #e5e5e5;
        border-top: 0;
      }
    }
  }
}
</style>